import React from 'react';

import Layout from '../components/Layout/Layout';
import Seo from '../components/Seo/Seo';
import LogoMobile from '../assets/images/logo-white.inline.svg';
import Logo from '../assets/images/logo.inline.svg';

import './index.css';

function IndexPage() {
  return (
    <Layout>
      <Seo title="Hyperbloq | B2B Back-End as a Service" />
      <LogoMobile alt="Hyperbloq Logo" className="logo-mobile" />
      <div className="message">
        <p>COMING SOON</p>
        <h1>
        Cuéntanos tu idea, <br />
        <strong>Queremos ayudarte a construirla.</strong>
        </h1>
      </div>
      <div className="contactForm">
        <div className="card">
          <Logo alt="Hyperbloq Logo" className="logo" />
          {/* <StaticImage
            src="../../../static/images/logo.png"
            placeholder="blurred"
            loading="eager"
            className="logo"
            width={200}
            quality={95}
            formats={['auto', 'webp', 'avif']}
            alt=""
          /> */}
          <form name="reachOutForm" method="POST" data-netlify="true" action="/thank-you">
            <input type="hidden" name="form-name" value="reachOutForm" />
            <label>Nombre<span>*</span></label>
            <input type="text" required name="name" placeholder="Nombre" />
            <label>Email<span>*</span></label>
            <input type="email" required name="email" placeholder="Email" />
            <label>Comentario<span>*</span></label>
            <textarea name="message" required placeholder="Comentario" rows="5"></textarea>
            <button type="submit" className="btn-primary">Enviar</button>
          </form>
        </div>
        <div className="copyright">
          <p>
              © {new Date().getFullYear()} &middot;
              {` `}
              Hyperbloq LLC
            </p>
        </div>
      </div>
    </Layout>
  );
}

export default IndexPage;
